<template>
  <v-container fluid class="custom-container-details">
    <div>
      <v-row v-if="validation && validation.isSystem" dense no-gutters class="px-0">
        <v-col cols="12">
          <v-alert color="secondary" data-cy="isSystem-hint">{{ $lang.hints.resourceIsSystem }}</v-alert>
        </v-col>
      </v-row>
      <v-row wrap no-gutters class="fill-height">
        <v-col
          v-if="!userCanEdit && !loading && (validation && !validation.isSystem)"
          cols="12"
          sm="12"
          md="10"
        >
          <v-alert dense color="warning" style="color: black">{{ $lang.errors.readOnly }}</v-alert>
        </v-col>
        <v-col
          cols="12"
          class="fill-height"
        >
          <v-tabs
            v-model="tab"
            background-color="transparent"
            class="custom-tab-design"
          >

            <v-tab
              :key="0"
              data-cy="tab-details"
              class="ml-2"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              :key="1"
              data-cy="tab-permissions"
              class="ml-2"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
            <v-tab
              :key="2"
              data-cy="tab-history"
              class="ml-2"
            >
              {{ $lang.labels.history }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class=" pt-6 custom-bg">
            <v-tab-item
              :key="0"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height rounded-0 custom-bg">
                <v-form
                  ref="form"
                  v-model="valid"
                  class="pt-1 "
                >
                  <v-row wrap no-gutters justify="space-between" >
                    <v-col
                      cols="12"
                      class="pr-sm-1 mt-1"
                    >
                      <v-row wrap no-gutters >
                        <!-- Name Text Field -->
                        <v-col
                          cols="6"
                          md="5"
                          lg="4"
                          class=" pr-md-0 d-flex"
                        >
                          <v-text-field
                            v-model="validation.name"
                            dense
                            :label="$lang.labels.name"
                            required
                            outlined
                            :rules="[(v) => !!v || 'Required!', v => (v && v.length > 1 && v.length <= 250) || $lang.errors.nameTooShortOrLong]"
                            class="required-asterisk copy-name-icon input-with-label"
                            :persistent-hint="isEdit"
                            :hint="formatHint"
                            :readonly="!userCanEdit"
                            append-icon="mdi-vector-combine"
                            @click:append="copyValidationName()"
                          ></v-text-field>
                        </v-col>

                        <!-- Autocomplete Field -->
                        <v-col
                          v-if="includesAnotherValidation"
                          cols="6"
                          class=" pl-2"
                        >
                          <v-autocomplete
                            v-model="validation.includes"
                            outlined
                            dense
                            :items="allValidations"
                            chips
                            multiple
                            clearable
                            closable-chips
                            hide-no-data
                            hide-selected
                            item-text="name"
                            item-value="id"
                            :label="$lang.labels.validations"
                            :placeholder="$lang.actions.startTyping"
                            prepend-inner-icon="mdi-cloud-search-outline"
                            :readonly="!userCanEdit"
                            data-cy="includes-validations"
                            class="required-asterisk"
                            :rules="[v => !!v || $lang.labels.required]"
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                small
                                v-bind="data.attrs"
                                close
                                class="ma-1"
                                @click="openValidation(data.item.id)"
                                @click:close="remove(data.item)"
                              >
                                {{ data.item.name }}
                              </v-chip>
                            </template>
                          </v-autocomplete>
                        </v-col>

                        <!-- Import Button -->
                        <v-col
                          cols="1"
                          class="text-center"
                        >
                          <v-btn
                            :disabled="lock || !userCanEdit"
                            icon
                            color="primary"
                            @click="showImport = true"
                          >
                            <v-icon>mdi-import</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>

                    <!-- Checkbox (Leave it where it is) -->
                    <v-col
                      cols="1"
                      sm="9"
                      class="pr-sm-1 mt-1 mb-1"
                    >
                      <v-checkbox
                        v-model="includesAnotherValidation"
                        :label="$lang.labels.includesAnotherValidation"
                        hide-details
                        class="mt-0 mb-2"
                      ></v-checkbox>
                    </v-col>

                    <v-col
                      v-if="includesAnotherValidation"
                      cols="12"
                      class="pr-sm-1 mb-1"
                    >
                    </v-col>
                  </v-row>
                  <v-row no-gutters align="center" class="pb-2">
                    <v-col cols="12" class="pb-0">
                      <div class="d-inline-flex justify-space-between mb-1" style="width: 100%; padding-right: 3px">
                        <div class="d-flex align-center py-1">
                          <h3>{{ $lang.labels.fields }}</h3>
                          <v-btn
                            color="primary"
                            outlined
                            class="ml-2 pa-1"
                            :disabled="!userCanEdit"
                            data-cy="add-new-field"
                            @click="validation.fields.push(JSON.parse(JSON.stringify(newField)))"
                          >

                            <v-icon small>mdi-plus</v-icon>
                            <span class="color-primary">{{ $lang.actions.addField }}</span>
                          </v-btn>
                        </div>
                        <div>
                          <v-btn
                            icon
                            color="primary"
                            class="ml-2"
                            style="margin-top: 2px"
                            title="copy"
                            @click="copyFields()"
                          >
                            <v-icon>mdi-vector-combine</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            color="primary"
                            class="ml-2"
                            style="margin-top: 2px"
                            title="paste"
                            @click="pasteFields()"
                          >
                            <v-icon>mdi-clipboard-outline</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="!loading"
                    wrap
                    no-gutters
                    class="pb-1"
                  >
                    <!------------------ Fields--------------------- -->
                    <template v-for="(item, i) in validation.fields">
                      <v-col
                        :key="i"
                        cols="12"
                        lg="6"
                        class="pb-0 pr-lg-2"
                      >
                        <v-card
                          class="mb-2 py-2 entity-field-custom-bordered-row"
                          style="min-height: 390px"
                        >
                          <v-row no-gutters>
                            <!-- New Column with cols="1" -->
                            <v-col cols="1" class="entity-field-custom-small-col align-center">
                              <div class="d-flex flex-column justify-space-between align-center fill-height">
                                <div class="d-flex flex-column fill-height">
                                  <v-btn
                                    color="primary"
                                    small
                                    width="28"
                                    min-width="unset"
                                    class="mb-1 px-2"
                                    outlined
                                    :disabled="parseInt(`${i}`) === 0"
                                  >
                                    <v-icon
                                      dark
                                      size="20"
                                      color="primary"
                                      data-direction="up"
                                      :data-index="`${i}`"
                                      @click="moveItemsAround"
                                    >mdi mdi-arrow-up</v-icon>
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    small
                                    width="28"
                                    min-width="unset"
                                    class="mb-1 px-2"
                                    outlined
                                    :disabled="parseInt(`${i}`) === validation.fields.length - 1"
                                  >
                                    <v-icon
                                      dark
                                      size="20"
                                      color="primary"
                                      data-direction="down"
                                      :data-index="`${i}`"
                                      @click="moveItemsAround"
                                    >mdi mdi-arrow-down</v-icon>
                                  </v-btn>
                                </div>
                                <v-btn
                                  outlined
                                  color="error"
                                  small
                                  width="28"
                                  min-width="unset"
                                  class="color-error-text px-2"
                                  @click="deleteRow(i)"
                                >
                                  <v-icon dark size="18">mdi-trash-can-outline</v-icon>
                                </v-btn>
                              </div>
                            </v-col>
                            <v-col cols="11" class="pr-1">
                              <v-row no-gutters >
                                <v-col cols="12" class="mb-2 d-flex entity-field-custom-large-col">
                                  <div class="d-inline-flex align-center justify-space-between pt-2" style="width: 100%">
                                    <div class="d-inline-flex align-center">
                                      <v-text-field
                                        v-model="item.name"
                                        dense
                                        :readonly="!userCanEdit"
                                        :label="$lang.labels.fieldName"
                                        required
                                        outlined
                                        class="pl-2 required-asterisk"
                                        :rules="[v => !!v || $lang.labels.required, v => v ? !v.startsWith('$.') || $lang.errors.cannotUseVariableFormat : true, v => v ? !v.startsWith('$') || $lang.errors.cannotUseVariableFormat : true]"
                                        :data-cy="`field-name-${i}`"
                                      ></v-text-field>
                                      <v-select
                                        v-model="item.dataType"
                                        :items="formattedDataTypes"
                                        :label="$lang.labels.dataType"
                                        outlined
                                        dense
                                        required
                                        item-text="text"
                                        item-value="value"
                                        class="pl-2 pr-2 required-asterisk"
                                        :rules="[v => !!v || $lang.labels.required]"
                                        :readonly="!userCanEdit"
                                        style="min-width: 115px"
                                        :data-cy="`field-dataType-${i}`"
                                        @change="['BOOLEAN', 'TIMESTAMP', 'INTEGER', 'FILE_JSON', 'FILE_XML', 'ARRAY'].includes(item.dataType) ? (item.rules = [], item.genericSubType = '', item.validationRuleId = null) : ['OBJECT'].includes(item.dataType) ? (item.rules = [], item.genericSubType = 'OBJECT') : item.genericSubType = ''"
                                      />
                                      <v-select
                                        v-if="['ARRAY'].includes(item.dataType)"
                                        v-model="item.genericSubType"
                                        :items="item.dataType === 'ARRAY' ? formattedDataSubTypes : formattedObjectDataSubTypes"
                                        :label="$lang.labels.genericSubType"
                                        outlined
                                        dense
                                        item-text="text"
                                        item-value="value"
                                        class="pr-2 required-asterisk"
                                        :readonly="!userCanEdit"
                                        :persistent-hint="item.genericSubType === 'OBJECT'"
                                        :hint="item.genericSubType === 'OBJECT' ? $lang.hints.subType : ''"
                                        style="min-width: 115px"
                                        :data-cy="`field-genericSubType-${i}`"
                                      />
                                    </div>
                                  </div>
                                </v-col>
                                <v-col
                                  v-if="['ARRAY', 'OBJECT'].includes(item.dataType) && item.genericSubType === 'OBJECT'"
                                  cols="12"
                                  class="pl-2 pt-2 pb-1 mb-2 entity-field-custom-large-col"
                                >
                                  <validations-search-field
                                    :item-validation="item"
                                    :item-index="i"
                                    :user-can-edit="userCanEdit"
                                    :resource-is-system="validation.isSystem"
                                    @validationRuleIdChanged="(val) => setValidationRuleId(val, i)"
                                  />
                                </v-col>
                                <v-col cols="12" class=" pl-2 pt-4 pr-2  entity-field-custom-large-col">
                                  <v-row no-gutters align="center" class=" mt-0">
                                    <v-col>
                                      <h5 class="text-h6 pb-2">{{ $lang.labels.rules }}</h5>
                                    </v-col>
                                    <v-col cols="12" >
                                      <v-card
                                        v-if="item.rules.length"
                                        elevation="10"
                                        outlined
                                        class="rules-card "
                                      >
                                        <template v-for="(rule, n) in item.rules">
                                          <v-row :key="10000 + n" no-gutters class=" validations-inputbg">
                                            <v-col
                                              cols="5"
                                              md="4"
                                            >
                                              <v-select
                                                v-model="rule.type"
                                                :items="formattedRuleTypes(i, n)"
                                                :label="$lang.labels.ruleType"
                                                outlined
                                                dense
                                                required
                                                class="pr-2"
                                                :readonly="!userCanEdit || !item.dataType"
                                                :data-cy="`rule-ruleType-${n}`"
                                              ></v-select>
                                            </v-col>
                                            <v-col
                                              cols="5"
                                              md="7"
                                              style="position: relative"
                                            >
                                              <v-text-field
                                                v-model="rule.value"
                                                dense
                                                outlined
                                                :readonly="!userCanEdit"
                                                required
                                                :type="['NUMBER', 'INTEGER', 'DOUBLE', 'ARRAY', 'LONG'].includes(item.dataType) ? 'number' : 'text'"
                                                :rules="[v => (!!v || v === 0 || v === '0') || 'Required!', v => isRegex(v, rule.type) || 'Invalid regex!']"
                                                :data-cy="`rule-value-${n}`"
                                                class="hideNumberArrows pr-2"
                                                @change="item.dataType === 'INTEGER' ? rule.value = Number(rule.value).toFixed() : ''"
                                              ></v-text-field>
                                              <v-btn
                                                v-if="rule.type === 'REGEX'"
                                                class="button-regex"
                                                style="position: absolute; top: 6px; right: 10px"
                                                icon
                                                small
                                                :disabled="!userCanEdit"
                                                @click="selectedItem = { text: '', value: rule.value || '', fieldIndex: i, ruleIndex: n }; showRegexEditor = true"
                                              >
                                                <v-icon small color="info">mdi-pencil</v-icon>
                                              </v-btn>
                                            </v-col>
                                            <v-col
                                              cols="2"
                                              md="1"
                                              class="remove-rule-btn-wrapper text-center pa-1 "
                                            >
                                              <v-btn
                                                outlined
                                                small
                                                width="28"
                                                min-width="unset"
                                                color="error"
                                                class="color-error-text px-2 pa-1"
                                                @click="deleteRule(i, n)"
                                              >
                                                <v-icon size="18" color="red">mdi-trash-can-outline</v-icon>
                                              </v-btn>
                                            </v-col>
                                          </v-row>
                                        </template>
                                      </v-card>
                                    </v-col>
                                    <v-col cols="12" class="pb-1">
                                      <div class="d-inline-flex align-center pb-1" style="width: 100%; padding-right: 17px">
                                        <v-btn
                                          color="primary"
                                          outlined
                                          :disabled="!userCanEdit
                                            || !item.dataType
                                            || item.rules.length > 1
                                            || ['BOOLEAN', 'TIMESTAMP', 'OBJECT'].includes(item.dataType)"
                                          data-cy="add-new-rule"
                                          @click="item.rules.push({ type: '', value: '' })"
                                        >

                                          <v-icon small>mdi-plus</v-icon>
                                          <span class=" pl-1">{{ $lang.actions.add }}</span>
                                        </v-btn>
                                      </div>
                                    </v-col>
                                    <v-col>
                                      <div class="d-flex align-center justify-end">
                                        <!-- Actions on the right -->
                                        <div class="d-flex align-center">
                                          <v-checkbox
                                            v-model="item.isRequired"
                                            class="mr-3 mt-2 field-required-checkbox"
                                            :label="$lang.labels.required"
                                            :data-cy="`field-isRequired-${i}`"
                                          ></v-checkbox>
                                          <v-btn
                                            icon
                                            color="primary"
                                            class="ml-2"
                                            style="margin-top: 2px"
                                            title="Duplicate"
                                            @click="duplicateField(item)"
                                          >
                                            <v-icon>mdi-content-duplicate</v-icon>
                                          </v-btn>
                                        </div>
                                      </div>

                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>

                        </v-card>
                      </v-col>
                    </template>

                  </v-row>
                  <v-col v-if="validation.fields && validation.fields.length > 0" class="pa-0 add-field-btn_bottom pb-4">
                    <v-btn
                      color="primary"
                      outlined
                      class=" pa-1"
                      :disabled="!userCanEdit"
                      data-cy="add-new-field"
                      @click="validation.fields.push(JSON.parse(JSON.stringify(newField)))"
                    >

                      <v-icon small>mdi-plus</v-icon>
                      <span class="color-primary">{{ $lang.actions.addField }}</span>
                    </v-btn>
                  </v-col>
                  <v-row no-gutters wrap justify="space-between">
                    <v-divider class="mb-4" />
                  </v-row>
                  <v-row no-gutters wrap justify="space-between">
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-flex align-center">
                        <v-btn
                          outlined
                          color="primary"
                          :to="{ name: 'validations', params: { lang: $lang.current_lang } }"
                        >
                          <v-icon
                            left
                            dark
                            class="mr-1"
                          >
                            mdi-arrow-left
                          </v-icon>
                          {{ $lang.actions.back }}
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="isEdit"
                          outlined
                          :action-text="$lang.actions.areYouSureYouWantToDelete"
                          :action-text-suffix="validation.name"
                          :title="$lang.actions.delete"
                          type="validation"
                          :is-disabled="!userCanDelete"
                          :button-text="$lang.actions.delete"
                          :button-color="'error'"
                          :data-cy="'validation-delete'"
                          :forced-option="true"
                          :trigger-force-logic="triggerForceLogic"
                          :regular-delete-errors-usages="regularDeleteErrorsUsages"
                          class="ml-2"
                          :delete-success="deleteSuccess"
                          :simple-error="err"
                          @submit="deleteValidationFunct($event)"
                          @closeDialog="''"
                          @closeAfterDelete="$router.push({ name: 'validations' })"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right"
                    >
                      <v-progress-circular v-if="lock || loading" indeterminate :color="loading ? 'success' : 'accent'"></v-progress-circular>
                      <v-btn
                        outlined
                        :disabled="!isFormValid || !isEdit"
                        color="primary"
                        class="ml-1 color-accent-text"
                        @click="copyCreateResource(validation, 'validationCreate', $router, '', $options.filters.formatDateTime(new Date()))"
                      >
                        <v-icon
                          left
                          dark
                        >
                          mdi-content-copy
                        </v-icon>
                        {{ $lang.actions.copyCreate }}
                      </v-btn>
                      <v-btn
                        :disabled="!isFormValid"
                        color="primary"
                        class="button-default-width ml-2"
                        @click="submit()"
                      >
                        <v-icon
                          left
                          dark
                          class="mr-1"
                        >
                          mdi mdi-floppy
                        </v-icon>
                        {{ $lang.actions.submit }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="1"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height custom-bg">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-2">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-2">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-2">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              class="fill-height"
            >
              <v-card class="py-1 fill-height custom-bg">
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters>
                    <v-col cols="12">
                      <Table
                        :items="history"
                        @fetchHistory="fetchHistory($event)"
                        @restoreHistory="restoreValidation($event)"
                      ></Table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-if="showRegexEditor" v-model="showRegexEditor" max-width="71%">
      <regex-editor
        :title="$lang.labels.regex"
        :item="selectedItem"
        :with-buttons="true"
        @closeDialog="showRegexEditor = false; selectedItem = null"
        @fromGlobalEditor="updateFromRegexEditor($event)"
      ></regex-editor>
    </v-dialog>
    <v-dialog v-if="showImport" v-model="showImport" max-width="80%" width="80%">
      <import-json-modal
        v-if="showImport"
        type="VALIDATION_RULE"
        @import="showImport = false; importFunction($event)"
        @closeDialog="showImport = false"
      />
    </v-dialog>
    <multi-errors-snackbar
      :show="showSnackErrors"
      :errors="errorsForSnackbar"
      @close="showSnackErrors = false; errorsForSnackbar = []"
    />
  </v-container>
</template>

<script>
import Table from '@/components/ui/datatables/FlowyHistoryDatatable'
import {
  getValidationRuleByIdUsingGET as getValidation,
  updateValidationRuleUsingPUT as updateValidation,
  createValidationRuleUsingPOST as createValidation,
  getHistoriesUsingGET as getHistories,
  getValidationRulesUsingGET as getValidations,
  deleteValidationRuleUsingDELETE as deleteValidation
} from '@/utils/api'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import copy from 'copy-to-clipboard'
import RegexEditor from '@/components/ui/RegexEditor.vue'
import ImportJsonModal from '@/components/ui/modals/ImportJsonModal.vue'
import MultiErrorsSnackbar from '@/components/ui/MultiErrorsSnackbar.vue'
import ValidationsSearchField from './ValidationsSearchField.vue'
import { canUserEditResource, copyCreateResource, getRolesWithoutAuth } from '@/utils/helpers'
import { mapActions } from 'vuex'
import { bus } from '@/main'

export default {
  components: {
    ImportJsonModal,
    RegexEditor,
    ActionButtonWithConfirmation,
    MultiErrorsSnackbar,
    Table,
    UserRolesSelect,
    ValidationsSearchField
  },
  data() {
    return {
      showImport: false,
      showRegexEditor: '',
      selectedItem: null,
      err: '',
      errorsForSnackbar: [],
      showSnackErrors: false,
      success: '',
      testResult: '',
      resultColor: '',
      tab: 0,
      valid: false,
      isEdit: false,
      loading: true,
      lock: false,
      validation: {
        createdOn: '',
        id: 0,
        modifiedOn: '',
        name: '',
        fields: [],
        includes: [],
        isSystem: false
      },
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      isSuperUser: false,
      history: { items: [], meta: {} },
      dataTypes: ['NUMBER', 'STRING', 'BOOLEAN', 'TIMESTAMP', 'INTEGER', 'DOUBLE', 'FILE_JSON', 'FILE_XML', 'ARRAY', 'OBJECT', 'LONG'].sort(),
      genericSubTypeTypes: ['BOOLEAN', 'DOUBLE', 'INTEGER', 'NUMBER', 'STRING', 'TIMESTAMP', 'OBJECT', 'LONG'].sort(),
      genericObjectSubTypeTypes: ['OBJECT'].sort(),
      ruleTypesNumber: ['MAX_VALUE', 'MIN_VALUE'],
      ruleTypesString: ['MAX_LENGTH', 'MIN_LENGTH', 'REGEX'],
      ruleTypesFile: ['MIN_SIZE', 'MAX_SIZE'].sort(),
      newField: {
        dataType: '',
        genericSubType: '',
        isRequired: true,
        name: '',
        rules: [],
        validationRule: {},
        validationRuleId: '',
        searchValidations: '',
        validations: [],
        isLoadingValidations: false
      },
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false,
      allValidations: [],
      userCanDelete: false,
      includesAnotherValidation: false
    }
  },
  computed: {
    formattedDataTypes () {
      return this.dataTypes.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedDataSubTypes () {
      return this.genericSubTypeTypes.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedObjectDataSubTypes () {
      return this.genericObjectSubTypeTypes.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.validation.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.validation.modifiedOn)}, ID: ${this.validation.id}`
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    },
    isFormValid() {
      return this.valid && !this.lock && this.userCanEdit
    }
  },
  watch: {
    isFormValid(val) {
      this.formValid(val)
    },
    includesAnotherValidation: {
      handler(val) {
        if (val === false) {
          this.validation.includes = []
        }
      },
      deep: true
    },
    tab() {
      if (this.tab === 2) {
        this.fetchHistory()
      }
    }
  },
  provide() {
    return {
      openValidation: this.openValidation
    }
  },
  mounted() {
    bus.$on('saveResource', this.submit)
  },
  beforeDestroy() {
    bus.$off('saveResource', this.submit)
  },
  created() {

    this.isEdit = this.$route.name === 'validationEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'VALIDATION_RULE_DELETER') || this.isSuperUser
    }

    this.init()
      .then(() => {
        getValidations({ page: 1,
          //TODO it was a very quick fix
          // we have to rewrite the search here in the future
          // to avoid fetching all validations at once, but only selected/found
          size: 10000 })
          .then((res) => {
            this.allValidations = res.data.data.items
          })
          .catch((err) => {

            this.addSnackbar({
              message: err,
              timeout: 5000,
              color: 'error'
            })
          })
        if (this.isEdit && this.$route.params.id) {
          this.loading = true
          getValidation({ id: this.$route.params.id })
            .then((res) => {
              this.validation = res.data.data

              if (this.validation && this.validation.includes && this.validation.includes.length > 0) {
                this.includesAnotherValidation = true
              }

              this.validation.fields.forEach((field) => {
                if (field.validationRuleId) {
                  field.validationRule = {}

                  getValidation({ id: field.validationRuleId })
                    .then((res) => {
                      if (res.status === 200) {
                        field.validationRule = res.data.data
                      } else {
                        field.validationRuleId = null
                      }
                    })
                }
              })

              this.editRolesIds = this.validation.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
              this.useRolesIds = this.validation.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
              this.viewRolesIds = this.validation.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

              this.userCanEdit = this.canUserEditResource(this.isSuperUser, !!this.editRolesIds.find((x) => this.userRolesIds.includes(x)), this.validation.isSystem)

              this.loading = false
            })
            .catch((err) => {
              this.addSnackbar({
                message: err,
                timeout: 5000,
                color: 'error'
              })            })
        } else if (this.$route.params.restore) {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          this.importFunction(this.$route.params.restore)
          this.loading = false
        } else if (this.$route.params.copyFromModal) {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          this.showImport = true
          this.loading = false
        } else {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          if (this.$route.params.copy) {
            this.importFunction(this.$route.params.copy)
          }
          this.loading = false
        }
      })
  },
  methods: {
    ...mapActions('app', ['addSnackbar', 'formValid']),

    canUserEditResource,
    copyCreateResource,
    setValidationRuleId(val, i) {
      this.validation.fields[i].validationRuleId = val
      if (val) {
        getValidation({ id: val })
          .then((res) => {
            if (res.status === 200) {
              this.validation.fields[i].validationRule = res.data.data
            } else {
              this.validation.fields[i].validationRuleId = null
            }
          })
      }
    },
    importFunction(importData) {
      this.validation = importData

      if (this.validation && this.validation.includes && this.validation.includes.length > 0) {
        this.includesAnotherValidation = true
      }

      this.validation.fields.forEach((field) => {
        if (field.validationRuleId) {
          field.validationRule = {}

          getValidation({ id: field.validationRuleId })
            .then((res) => {
              field.validationRule = res.data.data
            })
        }
      })
    },
    updateFromRegexEditor(value) {
      this.validation.fields[value.fieldIndex].rules[value.ruleIndex] = value.value
    },
    isRegex(str, type) {
      if (type !== 'REGEX') return true
      try {
        new RegExp(str)

        return true
      } catch (e) {
        return false
      }
    },
    moveItemsAround(e) {
      let toIndex = 0
      let canMove = true
      const clickedItemIndex = parseInt(e.target.dataset.index)
      const clickedDirection = e.target.dataset.direction

      if (clickedDirection === 'up') {
        if (clickedItemIndex !== 0) {
          toIndex = clickedItemIndex - 1
        } else {
          canMove = false
        }
      } else {
        if (clickedItemIndex !== this.validation.fields.length - 1) {
          toIndex = clickedItemIndex + 1
        } else {
          canMove = false
        }
      }

      if (canMove) {
        // eslint-disable-next-line prefer-destructuring
        const clickedEl = this.validation.fields.splice(clickedItemIndex, 1)[0]

        this.validation.fields.splice(toIndex, 0, clickedEl)
      }
    },
    remove (item) {
      const index = this.validation.includes.indexOf(item.id)

      if (index >= 0) this.validation.includes.splice(index, 1)
    },
    deleteValidationFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteValidation({ id: this.validation.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else if (res.response.status === 400 && !res?.response?.data?.data?.usages) {
            this.addSnackbar({
              message: res.response.data.statusText,
              timeout: 5000,
              color: 'error'
            })
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    duplicateField(item) {
      this.copyFields(item)
      this.pasteFields()
      localStorage.removeItem('copiedData')
    },
    copyFields(item = null) {
      const fieldsToCopy = item ? [item] : this.validation.fields

      localStorage.setItem('copiedData', JSON.stringify(fieldsToCopy))
    },
    copyValidationName() {
      copy(this.validation.name)
      this.addSnackbar({
        message: 'Copied',
        timeout: 3000,
        color: 'success'
      })

    },
    pasteFields() {
      let pasteData = localStorage.getItem('copiedData')

      pasteData = JSON.parse(pasteData)

      if (pasteData.length) {
        // eslint-disable-next-line array-callback-return
        pasteData.map((item) => {
          this.validation.fields.push(item)
        })
      }
    },
    openValidation(id) {
      window.open(`/${localStorage.selectedLanguage || 'en'}/validations/edit/${id}`, '_blank')
    },
    formattedRuleTypes (i, n) {
      const selectedTypes = this.validation.fields[i].rules.map((x) => x.type)

      if (['NUMBER', 'INTEGER', 'DOUBLE', 'LONG'].includes(this.validation.fields[i].dataType)) {
        return this.ruleTypesNumber.map((x) => {
          return { text: this.$lang.status[x], value: x }
        }).filter((x) => !selectedTypes.includes(x.value) || x.value === this.validation.fields[i].rules[n].type)
      }

      if (this.validation.fields[i].dataType === 'STRING') {
        return this.ruleTypesString.map((x) => {
          return { text: this.$lang.status[x], value: x }
        }).filter((x) => !selectedTypes.includes(x.value) || x.value === this.validation.fields[i].rules[n].type)
      }

      if (['FILE_JSON', 'FILE_XML'].includes(this.validation.fields[i].dataType)) {
        return this.ruleTypesFile.map((x) => {
          return { text: this.$lang.status[x], value: x }
        }).filter((x) => !selectedTypes.includes(x.value) || x.value === this.validation.fields[i].rules[n].type)
      }

      if (['ARRAY'].includes(this.validation.fields[i].dataType)) {
        return this.ruleTypesFile.map((x) => {
          return { text: this.$lang.status[`${x}_ARRAY`], value: x }
        }).filter((x) => !selectedTypes.includes(x.value) || x.value === this.validation.fields[i].rules[n].type)
      }

      return []
    },
    deleteRow(i) {
      this.validation.fields.splice(i, 1)
    },
    deleteRule(i, n) {
      this.validation.fields[i].rules.splice(n, 1)
    },
    fetchHistory(options) {
      if (!this.validation.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.validation.id
      obj.resourceType = 'VALIDATION_RULE'

      getHistories(obj)
        .then((res) => {
          this.history = res.data.data
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })         })
    },
    restoreValidation(data) {
      this.validation = data

      if (this.validation && this.validation.roles) {
        this.editRolesIds = this.validation.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
        this.useRolesIds = this.validation.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
        this.viewRolesIds = this.validation.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

        this.userCanEdit = this.canUserEditResource(this.isSuperUser, !!this.editRolesIds.find((x) => this.userRolesIds.includes(x)), this.validation.isSystem)

        if (this.validation && this.validation.includes && this.validation.includes.length > 0) {
          this.includesAnotherValidation = true
        }
      }
    },
    init() {
      return new Promise((resolve) => {
        getRolesWithoutAuth()
          .then((roles) => {
            this.allRoles = roles
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    async submit() {
      this.loading = true
      this.lock = true

      let res = null

      const localValidation = structuredClone(this.validation)

      const { id } = localValidation

      if (!this.isEdit) {
        delete localValidation.id
      }
      delete localValidation.createdOn
      delete localValidation.modifiedOn

      const tempRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      localValidation.roles = tempRoles

      localValidation.fields.forEach((field, index) => {
        field.rules.forEach((rule) => {
          if (['MAX_VALUE', 'MIN_VALUE', 'MAX_LENGTH', 'MIN_LENGTH', 'MIN_SIZE', 'MAX_SIZE'].includes(rule.type)) rule.value = Number(rule.value)
        })
        if (field.validationRule && field.validationRule.id) {
          field.validationRuleId = field.validationRule.id
          delete field.validationRule
        }

        if (['BOOLEAN', 'TIMESTAMP', 'INTEGER', 'FILE_JSON', 'FILE_XML', 'ARRAY'].includes(field.dataType) && field.genericSubType !== 'OBJECT') localValidation.fields[index].validationRuleId = null
        if (field.dataType === 'OBJECT') field.genericSubType = null
        if (field.genericSubType === '') field.genericSubType = null
      })

      try {

        res = this.isEdit ? await updateValidation({ id, body: localValidation }) : await createValidation({ body: localValidation })

        if (res && res.status !== 200) {
          if (res?.response?.data?.data?.[0]?.error) {

            res?.response?.data?.data?.forEach((error) => {
              this.errorsForSnackbar.push({
                text: error.error,
                value: null
              })
            })
            this.showSnackErrors = true
          } else {
            const errorMessage = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.validationUpdate : this.$lang.errors.validationCreate)

            this.addSnackbar({
              message: errorMessage,
              timeout: 5000,
              color: 'error'
            })
          }
          this.loading = false
          this.lock = false

          return
        }
        const successMessage = this.isEdit ? this.$lang.success.validationUpdated : this.$lang.success.validationCreated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })
        this.loading = false
        this.lock = false

        if (!this.isEdit) {
          this.$router.push({
            name: 'validationEdit',
            params: { id: res.data.data.id }
          })
        }

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.loading = false
        this.lock = false
      }
    }
  }
}
</script>
<style lang="scss">
.side-border {
  &-left {
    border-left: 1px solid var(--v-secondary-base);
  }
  &-right {
    border-right: 1px solid var(--v-secondary-base);
  }
}

.hideNumberArrows {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}

.copy-name-icon .v-icon{
  color: #009fb7;
}

.input-with-label .v-messages__message {
  padding-top: 2px;
}

.v-application {
  .elevation-1, .elevation-10 {
    box-shadow: none !important;
  }
}

.validation-form-container {
  max-width: 800px;

  @media screen and (min-width: map-get($grid-breakpoints, 'xl')) {
    max-width: unset;
  }
}

.rules-card {
  border: none !important;
}

.row.no-gutters > .col.remove-rule-btn-wrapper {
  @media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
    margin-top: -10px !important;
  }
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0;
}

.add-field-btn_bottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > h5 {
    padding-bottom: 18px;
    padding-right: 8px;
  }
}

.field-required-checkbox .v-input__slot {
  margin-bottom: 0;
}
</style>
